import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazyViewUserPlantDailyPowerQuery,
  useLazyViewUserPlantDetailsQuery,
  useLazyViewUserPlantGenerationQuery,
  useLazyViewUserPlantInvertersQuery,
} from "../../redux/api/generation/generationAPI";
import {
  selectSolarFilter,
  setSolarFilter,
} from "../../redux/features/solar-filter/solar-filter-slice";
import IViewUserSolarPlantDetailsResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-details-response-dto";
import IViewUserSolarPlantEnergyResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-energy-response-dto";
import IViewUserSolarPlantPowerResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-user-solar-plant-power-response-dto";
import IViewUserSolarPlantInvertersResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-user-solar-plantInverters-response-dto";
import { EDateTypes } from "../../shared/oversight-core/enums/date-types";
import { EUnitType } from "../../shared/oversight-core/enums/unit-type";
import { ISolarPlantInverterView } from "../../shared/oversight-core/interfaces/solar-plant-inverter-view";
import { ISolarPlantView } from "../../shared/oversight-core/interfaces/solar-plant-view";
import GaugeInfoCard from "../../shared/oversight-core/shared-components/gauge-info-card/gauge-info-card";
import InventerInfoCard from "../../shared/oversight-core/shared-components/inventer-info-card/inventer-info-card";
import SolarInfoCard from "../../shared/oversight-core/shared-components/solar-info-card/solar-info-card";
import WetherInfo from "../../shared/oversight-core/shared-components/wether-info/wether-info";
import AppSelect, {
  Option,
} from "../../shared/oversight-core/ui-elements/app-select/app-select";
import AreaChart from "../../shared/oversight-core/ui-elements/area-chart/area-chart";
import ArrowButton from "../../shared/oversight-core/ui-elements/arrow-button/arrow-button";
import ButtonWithBadge from "../../shared/oversight-core/ui-elements/buttons/button-with-badge/button-with-badge";
import Pagination from "../../shared/oversight-core/ui-elements/pagination/pagination";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import SolarFilterSidePanel from "./solar-filter-side-panel/solar-filter-side-panel";

interface ChartData {
  values: number[];
  labels: string[];
}

const defaultChartDataValues = {
  values: [],
  labels: [],
};

const unitTypes: Option[] = [
  {
    value: EUnitType.POWER,
    label: "Power(kW)",
  },
  {
    value: EUnitType.ENERGY,
    label: "Energy(kWh)",
  },
];

const Solar = () => {
  const { state: plant } = useLocation();
  const solarFilterStore = useSelector(selectSolarFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedUnitType, setSelectedUnitType] = useState<Option>({
    ...unitTypes[0],
  });
  const [powerChartData, setPowerChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [energyChartData, setEnergyChartData] = useState<ChartData>({
    ...defaultChartDataValues,
  });
  const [solarPlant, setSolarPlant] = useState<ISolarPlantView>();
  const [solarPlantInventers, setSolarPlantInventers] = useState<
    ISolarPlantInverterView[]
  >([]);
  const [showSolarFilterSidePanel, setShowSolarFilterSidePanel] =
    useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [filter, setFilter] = useState({ pageNumber: 0 });
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);

  const [triggerUserPlantDetails, { isFetching: isFetchingPlantDetails }] =
    useLazyViewUserPlantDetailsQuery();
  const [triggerUSerPlantInventers, { isFetching: isFetchingPlantInventers }] =
    useLazyViewUserPlantInvertersQuery();
  const [triggerDailyPlantPower, { isFetching: isFetchingDailyPlantPower }] =
    useLazyViewUserPlantDailyPowerQuery();
  const [triggerDailyPlantEnergy, { isFetching: isFetchingDailyPlantEnergy }] =
    useLazyViewUserPlantGenerationQuery();

  useEffect(() => {
    if (plant && plant.id) {
      triggerUserPlantDetails({ plantId: plant.id })
        .unwrap()
        .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
          setSolarPlant(res.solarPlantView);
          setIsFirstTimeLoading(false);
        })
        .catch(() => {
          console.log("Failed to get plant details");
        });
    }
  }, [triggerUserPlantDetails, plant]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (plant && plant.id) {
        triggerUserPlantDetails({ plantId: plant.id })
          .unwrap()
          .then((res: IViewUserSolarPlantDetailsResponseDTO) => {
            setSolarPlant(res.solarPlantView);
          })
          .catch(() => {
            console.log("Failed to get plant details");
          });
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [triggerUserPlantDetails, plant]);

  useEffect(() => {
    if (
      selectedUnitType.value === EUnitType.POWER &&
      solarFilterStore &&
      plant &&
      plant.id
    ) {
      triggerDailyPlantPower({
        plantId: plant.id,
        year: new Date(solarFilterStore.selectedDate).getFullYear(),
        month: new Date(solarFilterStore.selectedDate).getMonth() + 1,
        day: new Date(solarFilterStore.selectedDate).getDate(),
      })
        .unwrap()
        .then((res: IViewUserSolarPlantPowerResponseDTO) => {
          const power = res.solarPlantPowerView.solarPower;
          setPowerChartData({
            values: power.map((p) => p.power / 1000),
            labels: power.map((p) => moment(p.date).utc().format("HH:mm")),
          });
        })
        .catch(() => {
          setPowerChartData({ ...defaultChartDataValues });
        });
    }
  }, [triggerDailyPlantPower, solarFilterStore, selectedUnitType.value, plant]);

  useEffect(() => {
    if (
      selectedUnitType.value === EUnitType.ENERGY &&
      solarFilterStore &&
      plant &&
      plant.id
    ) {
      triggerDailyPlantEnergy({
        plantId: plant.id,
        year: new Date(solarFilterStore.selectedDate).getFullYear(),
        month: new Date(solarFilterStore.selectedDate).getMonth() + 1,
        day: new Date(solarFilterStore.selectedDate).getDate(),
        viewType: solarFilterStore.selectedDateType,
      })
        .unwrap()
        .then((res: IViewUserSolarPlantEnergyResponseDTO) => {
          const energy = res.solarPlantEnergyView.solarEnergy;
          setEnergyChartData({
            values: energy.map((e) => e.power),
            labels: energy.map((e) =>
              moment(e.date)
                .utcOffset("+05:30")
                .format(
                  solarFilterStore.selectedDateType === EDateTypes.DAILY
                    ? "MM-DD"
                    : solarFilterStore.selectedDateType === EDateTypes.MONTHLY
                    ? "MM-YYYY"
                    : "YYYY"
                )
            ),
          });
        })
        .catch(() => {
          setEnergyChartData({ ...defaultChartDataValues });
        });
    }
  }, [
    triggerDailyPlantEnergy,
    solarFilterStore,
    selectedUnitType.value,
    plant,
  ]);

  useEffect(() => {
    if (plant && plant.id) {
      triggerUSerPlantInventers({
        plantId: plant.id,
        pageNumber: filter.pageNumber,
        pageSize: 2,
      })
        .unwrap()
        .then((res: IViewUserSolarPlantInvertersResponseDTO) => {
          setSolarPlantInventers(res.genericPage.elements);
          setTotalElements(res.genericPage.totalElements);
        })
        .catch(() => {
          setTotalElements(0);
        });
    }
  }, [triggerUSerPlantInventers, filter.pageNumber, plant]);

  const onFilter = (selectedDate: Date, selectedDateType: EDateTypes) => {
    dispatch(
      setSolarFilter({
        selectedDate: moment(selectedDate).valueOf(),
        selectedDateType,
      })
    );
    setShowSolarFilterSidePanel(false);
  };

  const onClearAll = () => {
    dispatch(
      setSolarFilter({
        selectedDate: moment().valueOf(),
        selectedDateType: EDateTypes.DAILY,
      })
    );
    setShowSolarFilterSidePanel(false);
  };

  return (
    <>
      <Row className="mx-0">
        <Col>
          <ArrowButton onBack={() => navigate(-1)} />
        </Col>
      </Row>
      <Row className="mt-1 g-4">
        <Col className="col-12 col-lg-6 col-xl-4">
          <GaugeInfoCard
            value={solarPlant?.pvPower ?? 0}
            capacity={solarPlant?.pvCapacity ?? 0}
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-3 col-xl-4 col-xxl">
          <SolarInfoCard
            title="Today Generation"
            value={`${solarPlant?.dailyGeneration ?? 0} kWh`}
            icon="solar_power"
            iconColor="#D08700"
            backgroundColor="#F7F8EE"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-3 col-xl-4 col-xxl">
          <SolarInfoCard
            title="Today Income"
            value={`${solarPlant?.dailyIncome ?? 0} ${
              solarPlant?.currency ?? ""
            }`}
            icon="payments"
            iconColor="#8EBB13"
            backgroundColor="#F5F8EE"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Total Generation"
            value={`${solarPlant?.totalGeneration ?? 0} kWh`}
            icon="solar_power"
            iconColor="#B867B6"
            backgroundColor="#F8F6F8"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
        <Col className="col-12 col-sm-6 col-lg-6 col-xxl">
          <SolarInfoCard
            title="Total Income"
            value={`${solarPlant?.totalIncome ?? 0} ${
              solarPlant?.currency ?? ""
            }`}
            icon="payments"
            iconColor="#26A767"
            backgroundColor="#F0FAF6"
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="col-12 col-xl-9">
          <div className="container-white position-relative">
            <Row className="align-items-center justify-content-end">
              <Col className="col-auto">
                <AppSelect
                  className="mt-1"
                  selectedValue={{ ...selectedUnitType }}
                  options={[...unitTypes]}
                  onChangeOption={(selectedOption) => {
                    setSelectedUnitType(selectedOption);
                  }}
                  id="unit-type"
                />
              </Col>
              <Col className="col-auto ps-1">
                <ButtonWithBadge
                  text="Filter"
                  icon="filter_alt"
                  onClick={() => {
                    setShowSolarFilterSidePanel(true);
                  }}
                  badgeValue={0}
                />
              </Col>
            </Row>
            <AreaChart
              borderColor1="#2DEC9F"
              backgroundColor1="#29CC3917"
              label1="Solar Power"
              yAxesUnit={
                selectedUnitType.value === EUnitType.POWER ? `kW` : `kWh`
              }
              labels={
                selectedUnitType.value === EUnitType.POWER
                  ? powerChartData.labels
                  : energyChartData.labels
              }
              data1={
                selectedUnitType.value === EUnitType.POWER
                  ? powerChartData.values
                  : energyChartData.values
              }
              isChartDataAvailable={
                selectedUnitType.value === EUnitType.POWER
                  ? powerChartData.values.length > 0
                  : energyChartData.values.length > 0
              }
            />
            <SpinnerModal
              show={isFetchingDailyPlantPower || isFetchingDailyPlantEnergy}
            />
          </div>
        </Col>
        <Col className="col-12 col-xl-3 mt-4 mt-xl-0">
          <WetherInfo
            longitude={solarPlant?.longitude ?? 0}
            latitude={solarPlant?.latitude ?? 0}
            address={solarPlant?.address ?? ""}
            isFetching={isFetchingPlantDetails && isFirstTimeLoading}
          />
        </Col>
      </Row>
      <Row className="align-items-center mt-5">
        <Col className="text-dark font-weight-600 font-size-24">Inverters</Col>
      </Row>
      {isFetchingPlantInventers ? (
        <Row>
          <Col>
            <SpinnerModal show={isFetchingPlantInventers} withOverlay={false} />
          </Col>
        </Row>
      ) : (
        <>
          {solarPlantInventers.length > 0 ? (
            <div className="mt-4 position-relative">
              {solarPlantInventers.map((s) => {
                return (
                  <Row key={s.plantId} className="mt-2">
                    <Col>
                      <InventerInfoCard solarPlantInventer={s} />
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : (
            <>
              {!isFetchingPlantInventers && (
                <div className="mt-4 container-dash text-center text-light font-weight-400 font-size-14">
                  There are no inventers
                </div>
              )}
            </>
          )}
        </>
      )}
      {solarPlantInventers.length > 0 && (
        <Pagination
          itemsPerPage={2}
          length={totalElements}
          currentPage={filter.pageNumber + 1}
          updateCurrentPage={(pn) => {
            setFilter((ps) => ({ ...ps, pageNumber: pn - 1 }));
          }}
        />
      )}
      <SolarFilterSidePanel
        showFilter={showSolarFilterSidePanel}
        setShowFilter={(value: boolean) => {
          setShowSolarFilterSidePanel(value);
        }}
        onFilter={onFilter}
        onClearAll={onClearAll}
        selectedUnitType={selectedUnitType.value as EUnitType}
      />
    </>
  );
};

export default Solar;
